import React, { useCallback, useEffect, useState }         from 'react'
import Announcement                             from './Announcement'
import { announcementIdAction }                 from "../Redux/Actions"
import { Box, Divider, Paper, Typography }      from '@material-ui/core'
import { createBrowserHistory }                 from 'history';
import { store }                                from "../Redux/Store"
import GetAnnouncementsData                     from '../Data/GetAnnoucementsData'

const history = createBrowserHistory();

const LandingPageAnnouncements = props => {

   const [ allAnnouncements,                    setAllAnnouncements  ] = useState([])
   const [ announcementId,                      setAnnouncementId    ] = useState('')

   const getData = useCallback( (d) => {
         setAllAnnouncements(d)
   }, [] )

   /* 
    * Set announcementId back to 0 in case it was changed by:
    * <EditOneAnnouncement />
    * <ShowOneAnnouncement />
    */
   useEffect(() => {
      store.dispatch(announcementIdAction(''))
   }, [])
   

   const handleUpdateAfterDeleteAnnouncement = (id) => {
      const updatedAnnouncements = allAnnouncements.filter(announcement => announcement.id !== id)
      setAllAnnouncements(updatedAnnouncements)
   }

   const showOneAnnouncement = (id) => {
      history.push('https::/www.usaftpsalumni.com')
      setAnnouncementId(id)
   }

   const displayAnnouncements = allAnnouncements.map((announcement, i) => {
      const { _id, paragraph, synopsis, id, title } = announcement
      // i is sent to inhibit the first announcement Divider, OTW there are two dividers under the Header
      return <Announcement key={_id} synopsis={synopsis} i={i} announcementId={_id} title={title}
            paragraph={paragraph}
            updateAfterDeleteAnnouncement={handleUpdateAfterDeleteAnnouncement}
            showOneAnnouncement={showOneAnnouncement}
      />
   })

   return (
      <Paper style={{ padding: "0.25rem", marginTop: "1rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }} >
         <GetAnnouncementsData getData={getData}/>
         
         <Typography variant="h6" style={{ marginTop: "1rem" }} className="Landing-announcements-Header">
            Announcements
            </Typography>
         <Box style={{ width: "100%", paddingTop: "1rem" }}>
            <Divider light />
         </Box>
         <div className="Landing-announcements">
            {displayAnnouncements}
         </div>
      </Paper>
   )
}

export default LandingPageAnnouncements
