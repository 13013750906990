import React, { useEffect, useState }        from 'react'
import axios                                 from "axios"
import Box                                   from "@material-ui/core/Box"
import Button                                from "@material-ui/core/Button"
import DatePicker                            from "react-datepicker";
import {
   CLIENT_PUBLIC_TOKEN,
   node_server
 }                                           from '../Helpers'
import Grid                                  from "@material-ui/core/Grid"
import MySelect                              from "../Components/MySelect"
import                                       "react-datepicker/dist/react-datepicker.css";
import { Redirect }                          from "react-router-dom"
import { store }                             from "../Redux/Store"
import TextareaAutosize                      from "@material-ui/core/TextareaAutosize"
import TextField                             from "@material-ui/core/TextField"
import Typography                            from "@material-ui/core/Typography"

const EditOneAnnouncement = () => {

   const [ _id,                              set_id                              ] = useState('')
   const [ addedDate,                        setAddedDate                        ] = useState('')
   const [ announcementIndex,                setAnnouncementIndex                ] = useState(-1)
   const [ announcementPara0,                setAnnouncementPara0                ] = useState('')
   const [ announcementPara1,                setAnnouncementPara1                ] = useState('')
   const [ announcementPara2,                setAnnouncementPara2                ] = useState('')
   const [ announcementPara3,                setAnnouncementPara3                ] = useState('')
   const [ announcementPara4,                setAnnouncementPara4                ] = useState('')
   const [ announcementPara5,                setAnnouncementPara5                ] = useState('')
   const [ announcementPara6,                setAnnouncementPara6                ] = useState('')
   const [ announcementPara7,                setAnnouncementPara7                ] = useState('')
   const [ announcementPara8,                setAnnouncementPara8                ] = useState('')
   const [ announcementPara9,                setAnnouncementPara9                ] = useState('')
   const [ synopsis,                         setSynopsis                         ] = useState('')
   const [ expiresDate,                      setExpiresDate                      ] = useState(new Date())
   const [ expiresDatePrevious,              setExpiresDatePrevious              ] = useState('')
   const [ expireTime,                       setExpireTime                       ] = useState('')
   const [ priority,                         setPriority                         ] = useState(0)
   const [ navigateBackToAnnouncementPage,   setNavigateBackToAnnouncementPage   ] = useState(false)
   const [ title,                            setTitle                            ] = useState('')

   useEffect( () => {
      const { announcementId } = store.getState()

      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + `announcement/getOneAnnouncement/${announcementId}`)
         .then( res => res.data )
         .then( data => {
               const { _id, addedDate, announcementIndex, expiresDate, expireTime, paragraph, priority, synopsis, title } = data

               set_id(_id)
               setAddedDate(addedDate)
               setAnnouncementIndex(announcementIndex)
               setAnnouncementPara0(paragraph[0])
               setAnnouncementPara1(paragraph[1])
               setAnnouncementPara2(paragraph[2])
               setAnnouncementPara3(paragraph[3])
               setAnnouncementPara4(paragraph[4])
               setAnnouncementPara5(paragraph[5])
               setAnnouncementPara6(paragraph[6])
               setAnnouncementPara7(paragraph[7])
               setAnnouncementPara8(paragraph[8])
               setAnnouncementPara9(paragraph[9])
               setSynopsis(synopsis)
               setExpiresDatePrevious(expiresDate)
               setExpireTime(expireTime)
               setPriority(priority)
               setTitle(title)
               // setUserId(userId)

               return 1
         })
   }, [] )

   const handleAnnouncementSynopsisChange = (e) => {
      setSynopsis(e.target.value)
   }

   const handleAnnouncementIndexChange = (e) => {
      setAnnouncementIndex(e.target.value)
   }

   const handlePara0Change = (e) => {
      setAnnouncementPara0(e.target.value)
   }
   
   const handlePara1Change = (e) => {
      setAnnouncementPara1(e.target.value)
   }

   const handlePara2Change = (e) => {
      setAnnouncementPara2(e.target.value)
   }

   const handlePara3Change = (e) => {
      setAnnouncementPara3(e.target.value)
   }

   const handlePara4Change = (e) => {
      setAnnouncementPara4(e.target.value)
   }

   const handlePara5Change = (e) => {
      setAnnouncementPara5(e.target.value)
   }

   const handlePara6Change = (e) => {
      setAnnouncementPara6(e.target.value)
   }

   const handlePara7Change = (e) => {
      setAnnouncementPara7(e.target.value)
   }

   const handlePara8Change = (e) => {
      setAnnouncementPara8(e.target.value)
   }

   const handlePara9Change = (e) => {
      setAnnouncementPara9(e.target.value)
   }


   const handleDateChange = date => {
      setExpiresDate(date)
   }

   const handlePriorityChange = (e) => {
      setPriority(e.target.value)
   }

   const handleTitleChange = (e) => {
      setTitle(e.target.value)
   }

   const handleSubmit = () => {

      const { announcementId, signedInUserId } = store.getState()

      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.put(node_server + 'announcement/editOneAnnouncement/', {
         _id,
         addedDate,
         paragraph: [
            announcementPara0,
            announcementPara1,
            announcementPara2,
            announcementPara3,
            announcementPara4,
            announcementPara5,
            announcementPara6,
            announcementPara7,
            announcementPara8,
            announcementPara9
         ],
         expiresDate,
         expireTime,
         priority,
         signedInUserId,
         synopsis,
         title,
      })
         .then( res => res.data )
         .then( data => {
               if(data.msg === 'good operation'){
                  setNavigateBackToAnnouncementPage(true)
               }
               // else if(data.msg === 'bad operation') {

               // }
               return 1
         })

   }

   const priorities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

   return (
      <Box style={{ marginTop: "6rem" }}>
         <Typography variant="h3">Edit Announcement</Typography>
         {navigateBackToAnnouncementPage ? <Redirect to="/AnnouncementLanding" /> : null}
         <form >
            <Grid container direction="row" justifyContent="center" style={{ width: "90%" }} >
               <Grid item xs={10}>
                  <TextField fullWidth label="Announcement Index" name="announcement-index" value={announcementIndex} onChange={handleAnnouncementIndexChange} />
               </Grid>
               <Grid item xs={10}>
                  <TextField fullWidth label="Title" name="title" value={title} onChange={handleTitleChange} />
               </Grid>
               <Grid item xs={10}>
                  <Typography style={{ marginTop: "1.5rem", textAlign: "left" }}>
                        Synopsis ( Please keep the synopsis to 3 - 5 sentences if possible )
                  </Typography>
                  <TextareaAutosize label="Synopsis" name="announcementSynopsis" value={synopsis} style={{ width: "100%", fontSize: "1rem" }} onChange={handleAnnouncementSynopsisChange} />
               </Grid>
               <Grid item xs={10}>
                  <Typography style={{ marginTop: "1.5rem", textAlign: "left" }}>
                        Individual Body Paragraphs Go Below
                  </Typography>
               </Grid>
               <Grid item xs={10}>
                  <TextareaAutosize label="Announcement Paragraph0" name="announcementPara0" value={announcementPara0} style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={handlePara0Change} />
               </Grid>
               <Grid item xs={10}>
                  <TextareaAutosize label="Announcement Paragraph1" name="announcementPara1" value={announcementPara1} style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={handlePara1Change} />
               </Grid>
               <Grid item xs={10}>
                  <TextareaAutosize label="Announcement Paragraph2" name="announcementPara2" value={announcementPara2} style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={handlePara2Change} />
               </Grid>
               <Grid item xs={10}>
                  <TextareaAutosize label="Announcement Paragraph3" name="announcementPara3" value={announcementPara3} style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={handlePara3Change} />
               </Grid>
               <Grid item xs={10}>
                  <TextareaAutosize label="Announcement Paragraph4" name="announcementPara4" value={announcementPara4} style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={handlePara4Change} />
               </Grid>
               <Grid item xs={10}>
                  <TextareaAutosize label="Announcement Paragraph5" name="announcementPara5" value={announcementPara5} style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={handlePara5Change} />
               </Grid>
               <Grid item xs={10}>
                  <TextareaAutosize label="Announcement Paragraph6" name="announcementPara6" value={announcementPara6} style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={handlePara6Change} />
               </Grid>
               <Grid item xs={10}>
                  <TextareaAutosize label="Announcement Paragraph7" name="announcementPara7" value={announcementPara7} style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={handlePara7Change} />
               </Grid>
               <Grid item xs={10}>
                  <TextareaAutosize label="Announcement Paragraph8" name="announcementPara8" value={announcementPara8} style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={handlePara8Change} />
               </Grid>
               <Grid item xs={10}>
                  <TextareaAutosize label="Announcement Paragraph9" name="announcementPara9" value={announcementPara9} style={{ marginTop: "1.5rem", width: "100%", fontSize: "1rem" }} onChange={handlePara9Change} />
               </Grid>
               <Grid item xs={3} style={{ marginTop: "0.5rem" }}>
                  <MySelect name="priority" label="Priority" options={priorities} onChange={handlePriorityChange} />
               </Grid>

                  <Grid item xs={4} style={{ marginTop: "1.5rem" }}>
                        <Typography style={{paddingLeft: "1rem", paddingRight: "1rem"}}>Click the x to keep the Current Expiration Date shown to the right,
                           or click in the box to select a new Expiration Date.
                        </Typography>
                        <DatePicker showTimeSelect selected={expiresDate} onChange={handleDateChange} isClearable
                           timeFormat="HH:mm" timeIntervals={15} timeCaption="time" dateFormat="MMMM d, yyyy h:mm aa"
                        />
                  </Grid>

                  <Grid item xs={4} style={{ marginTop: "1.5rem" }}>
                        <Typography style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
                           <strong>Current Expiration Date:</strong> {expiresDatePrevious}
                        </Typography>
                  </Grid>

               <Grid item xs={1} style={{ marginTop: "1.5rem" }}>
                  <Button variant="contained" color="primary" style={{ justifySelf: "left" }} onClick={handleSubmit}>Submit</Button>
               </Grid>
            </Grid>
         </form>
      </Box>
   )
}
export default EditOneAnnouncement