/* This is the page for users who can CRUD Announcements */

import React, { useEffect, useState }  from 'react'
import Announcement                    from "../Components/Announcement"
import                                 "./AnnouncementLanding.css"
import axios                           from "axios"
import Box                             from "@material-ui/core/Box"
import Button                          from '@material-ui/core/Button';
import Divider                         from '@material-ui/core/Divider';
import {
   CLIENT_PUBLIC_TOKEN,
   node_server
}                                      from '../Helpers'
import Grid                            from "@material-ui/core/Grid"
import { Link, Redirect }              from "react-router-dom"
import Paper                           from "@material-ui/core/Paper"
import Typography                      from "@material-ui/core/Typography"

const AnnouncementLanding = props => {

   const [allAnnouncements,            setAllAnnouncements        ] = useState([])
   const [announcementId,              setAnnouncementId          ] = useState(0)
   const [editOneAnnouncement,         setEditOneAnnouncement     ] = useState(false)
   const [showOneAnnouncement,         setShowOneAnnouncement     ] = useState(false)

   useEffect(() => {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + 'announcement')
         .then(res => res.data)
         .then(data => {
               setAllAnnouncements(data)
               return data
         })
   }, [])

   const handleShowOneAnnouncement = (id) => {
      setAnnouncementId(id)
      setShowOneAnnouncement(true)
   }

   const handleEditOneAnnouncement = (id) => {
      setAnnouncementId(id)
      setEditOneAnnouncement(true)
   }

   const handleUpdateAfterDeleteAnnouncement = (id) => {
      const updatedAnnouncements = allAnnouncements.filter(announcement => announcement.id !== id)
      setAllAnnouncements(updatedAnnouncements)
   }

   const displayAnnouncements = allAnnouncements.map((announcement, i) => {
      // i is sent to inhibit the first announcement Divider, OTW there are two dividers under the Header
      const { _id, paragraph, synopsis, id, title } = announcement
      return <Announcement key={_id} synopsis={synopsis} i={i} announcementId={_id} title={title}
         paragraph={paragraph}
         updateAfterDeleteAnnouncement={handleUpdateAfterDeleteAnnouncement}
         showOneAnnouncement={handleShowOneAnnouncement}
         editOneAnnouncement={handleEditOneAnnouncement}
      />
   })

   return (
      <Box style={{marginTop: '6rem'}}>
         {(announcementId !== '' && showOneAnnouncement) ? <Redirect to="/ShowOneAnnouncement" /> : null}
         {(announcementId !== '' && editOneAnnouncement) ? <Redirect to="/EditOneAnnouncement" /> : null}
         <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
               <Typography variant="h3">
                  Create, Read, Update, Delete Announcements
                  </Typography>
         </Grid>

         <Grid container direction="row" style={{ margin: "2rem" }}>
               <Grid item xs={12} sm={6} md={5}>
                  <Paper style={{ padding: "0.5rem", backgroundColor: "rgba(211, 211, 211, 0.2)" }} >
                     <Typography variant="h6" style={{ marginTop: "1rem" }} className="AnnouncementsLanding-Header">
                           Announcements
                              </Typography>
                     <Box style={{ width: "100%", paddingTop: "1rem" }}>
                           <Divider light />
                     </Box>
                     <div className="AnnouncementsLanding">
                           {displayAnnouncements}
                     </div>
                  </Paper>
               </Grid>
               <Grid item xs={12} sm={6} md={4}>
                  <Grid item>
                     <Link to="/NewAnnouncement" style={{ textDecoration: "none" }} >
                           <Button data-testid='crud-announcements-button' variant="contained" color="primary">
                              Create a New Announcement
                              </Button>
                     </Link>
                  </Grid>
               </Grid>
         </Grid>
      </Box>
   )
}

export default AnnouncementLanding