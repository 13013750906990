/**
 * This compoenent is called by
 * <AddPhoto />
 * <EditPoto />
 */

import React, { useState }                                              from 'react'
import axios                                                            from 'axios'
import { Button, Grid, TextField, TextareaAutosize, Typography }        from '@material-ui/core'
import {
   CLIENT_PUBLIC_TOKEN,
   formPhotoTagArray,
   node_server
}                                                                       from '../Helpers'
import PropTypes                                                        from 'prop-types'
import { store }                                                        from '../Redux/Store'

const AddEditPhotoFieldsComponent = props => {

   const { handleSnackBar, title } = props

   const { username } = store.getState()

   const [ caption,              setCaption              ] = useState('')
   const [ fullFilename,         setFullFilename         ] = useState('')
   const [ photoTags,            setPhotoTags            ] = useState('')
   const [ smallFilename,        setSmallFilename        ] = useState('')
   const [ thumbnailFilename,    setThumbnailFilename    ] = useState('')
   const [ tpsClass,             setTpsClass             ] = useState('')

   const changeCaption = (e) => {
      setCaption(e.target.value)
   }

   const changeFullFilename = (e) => {
      setFullFilename(e.target.value)
   }

   const changePhotoTags = (e) => {
      setPhotoTags(e.target.value)
   }

   const changeSmallFilename = (e) => {
      setSmallFilename(e.target.value)
   }

   const changeThumbnailFilename = (e) => {
      setThumbnailFilename(e.target.value)
   }

   const changeTpsClass = (e) => {
      setTpsClass(e.target.value)
   }

   const handleSubmit = async () => {
      const tagArray = await formPhotoTagArray(photoTags)

      axios.post( node_server + 'photo', 
      {
         addedBy: username,
         CLIENT_PUBLIC_TOKEN,
         caption,
         fullFilename,
         photoTags: tagArray,
         smallFilename,
         thumbnailFilename,
         tpsClass
      })
      .then( res => {
         if (res.data._id) handleSnackBar()
      })
   }

   return(
      <Grid>
         <Grid>
            <Typography variant='h4' style={{marginTop: '6rem'}} >
               {title}
            </Typography>
         </Grid>
         <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <TextField data-testid='add-photo-full-filename-input' label="Full Filename" onChange={changeFullFilename} variant='outlined' style={{ width: '90%' }} value={fullFilename} />
         </Grid>
         <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <TextField data-testid='add-photo-small-filename-input' label="Small Filename" onChange={changeSmallFilename} variant='outlined' style={{ width: '90%' }} value={smallFilename} />
         </Grid>
         <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <TextField data-testid='add-photo-thumbnail-filename-input' label="Thumbnail Filename" onChange={changeThumbnailFilename} variant='outlined' style={{ width: '90%' }} value={thumbnailFilename} />
         </Grid>
         <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <TextField data-testid='add-photo-photo-tag-input' label="Photo Tag(s), comma delimited, no spaces" onChange={changePhotoTags} variant='outlined' style={{ width: '90%' }} value={photoTags} />
         </Grid>
         <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <TextField data-testid='add-photo-tps-class-input' label="TPS Class" onChange={changeTpsClass} variant='outlined' style={{ width: '90%' }} value={tpsClass} />
         </Grid>
         <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <TextareaAutosize data-testid='add-photo-caption-input' name='Caption' placeholder={'Caption'} minRows={5} style={{ width: '90%', fontSize: '1.5rem', padding: '0.5rem' }} onChange={changeCaption} value={caption} />
         </Grid>
         <Grid item style={{ marginTop: '1rem' }}>
            <Button data-testid='add-photo-submit-button' variant='contained' color='primary' onClick={handleSubmit}>Submit</Button>
         </Grid>
      </Grid>
   )
}

AddEditPhotoFieldsComponent.propTypes = {
   handleSnackBar: PropTypes.func,
   title: PropTypes.string
}

export default AddEditPhotoFieldsComponent