import React, {
   useCallback,
   useState
}                                         from 'react'
import Box                                from '@material-ui/core/Box'
import GetOneAnnouncement                 from '../Data/GetOneAnnouncement'
import Grid                               from '@material-ui/core/Grid'
import IconButton                         from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon              from '@material-ui/icons/KeyboardBackspace';
import Paper                              from '@material-ui/core/Paper'
import { Redirect }                       from "react-router-dom"
import { store }                          from "../Redux/Store"
import Typography                         from '@material-ui/core/Typography'

const ShowOneAnnouncement = () => {

   const [ announcement,                 setAnnouncement               ] = useState({})
   const [ backToAnnouncementPage,       setBackToAnnouncementPage     ] = useState(false)

   const { announcementId } = store.getState()

   const getAnnouncement = useCallback( (data) => {
      setAnnouncement(data)
   }, [] )

   const handleBack = () => {
      setBackToAnnouncementPage(true)
   }

   return (
      <Box style={{marginTop: '8rem'}}>
         { (backToAnnouncementPage) ? <Redirect to="/" /> : null }
         <GetOneAnnouncement getAnnouncement={getAnnouncement} id={announcementId} />
         <Grid container direction="row" style={{ margin: "4rem", textAlign: "left", width: "90%" }}>
               <Paper style={{ padding: "2rem", backgroundColor: "rgba(211, 211, 211, 0.25)" }}>
                  <Grid item xs={12} style={{marginBottom: '1rem'}}>
                     <IconButton data-testid='show-one-announcement-back-button' onClick={handleBack} style={{border: '0.5px solid white', backgroundColor: 'blue'}}>
                           <KeyboardBackspaceIcon style={{color: 'white'}}/>
                     </IconButton>
                  </Grid>
                  {announcement.title ?
                  <Grid item xs={12}>
                     <Typography variant="h6">
                           <strong>Title:</strong> {announcement.title}
                     </Typography>
                  </Grid>
                  : null }
                  {announcement.synopsis ?
                  <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
                     <Typography variant="h6">
                           <strong>Synopsis:</strong> {announcement.synopsis}
                     </Typography>
                  </Grid>
                  : null }
                  {announcement.paragraph ?
                     <Grid item xs={12} style={{ marginTop: "0.5rem" }}>
                           <Typography variant="h6">
                              <strong>Body:</strong>
                           </Typography>
                     </Grid>
                     : null}
                  {announcement.paragraph && announcement.paragraph[0] ?
                     <Grid item xs={12} style={{ marginTop: "1rem" }}>
                           <Typography>{announcement.paragraph[0]}</Typography>
                     </Grid>
                     : null}
                  {announcement.paragraph && announcement.paragraph[1] ?
                     <Grid item xs={12} style={{ marginTop: "1rem" }}>
                           <Typography>{announcement.paragraph[1]}</Typography>
                     </Grid>
                     : null}
                  {announcement.paragraph && announcement.paragraph[2] ?
                     <Grid item xs={12} style={{ marginTop: "1rem" }}>
                           <Typography>{announcement.paragraph[2]}</Typography>
                     </Grid>
                     : null}
                  {announcement.paragraph && announcement.paragraph[3] ?
                     <Grid item xs={12} style={{ marginTop: "1rem" }}>
                           <Typography>{announcement.paragraph[3]}</Typography>
                     </Grid>
                     : null}
                  {announcement.paragraph && announcement.paragraph[4] ?
                     <Grid item xs={12} style={{ marginTop: "1rem" }}>
                           <Typography>{announcement.paragraph[4]}</Typography>
                     </Grid>
                     : null}
                  {announcement.paragraph && announcement.paragraph[5] ?
                     <Grid item xs={12} style={{ marginTop: "1rem" }}>
                           <Typography>{announcement.paragraph[5]}</Typography>
                     </Grid>
                     : null}
                  {announcement.paragraph && announcement.paragraph[6] ?
                     <Grid item xs={12} style={{ marginTop: "1rem" }}>
                           <Typography>{announcement.paragraph[6]}</Typography>
                     </Grid>
                     : null}
                  {announcement.paragraph && announcement.paragraph[7] ?
                     <Grid item xs={12} style={{ marginTop: "1rem" }}>
                           <Typography>{announcement.paragraph[7]}</Typography>
                     </Grid>
                     : null}
                  {announcement.paragraph && announcement.paragraph[8] ?
                     <Grid item xs={12} style={{ marginTop: "1rem" }}>
                           <Typography>{announcement.paragraph[8]}</Typography>
                     </Grid>
                     : null}
                  {announcement.paragraph && announcement.paragraph[9] ?
                     <Grid item xs={12} style={{ marginTop: "1rem" }}>
                           <Typography>{announcement.paragraph[9]}</Typography>
                     </Grid>
                     : null}
               </Paper>
         </Grid>
      </Box>
   )
}

export default ShowOneAnnouncement
