import { createStore } from "redux"
import reducer from "../Reducers"

const initialState = {
    admin: false,
    allGrads: [],
    authorizedToCrudAnnouncements: false,
    announcementId: '',
    alumni: false,
    bio_tbl_user_data: {},
    classData: {},
    classPage: false,
    goodLogin: false,
    hasBio: false,
    idOfStoryToShow: '',
    isActive: false,
    jwt: '',
    logout: false,
    phpFileName: '',
    resetPassword: false,
    signedInUsername: '',
    showAlumniBioNavbarButton: true,
    showClassPagesNavbarButton: true,
    showHistoryPageNavbarButton: true,
    showHomeNavbarButton: true,
    showMenuNavbarButton: false,
    signedInUserId: '',
    story: {},
    superUser: false,
    tpsClass: '',
    username: '',
    userId: '',
    userIdAuthorizedToCrudWeeklyUpdate: 0,
    userIdOfBioToAdd: '',
    userIdOfBioToEdit: '',
    userIdOfBioToShow: '',
    userIdOfProfieToEdit: '',
    userIdOfStoryToAdd: '',
    userType: ''
}

export const store = createStore(reducer, initialState)

/*
 * showAlumniBioNavbarButton    this gets set in <AddGrad />
 */