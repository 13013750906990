/**
 * 
 */

import React, { useState }                                           from 'react'
import AddPhotoFieldsComponent                                       from '../Components/AddPhotoFieldsComponent'
import { Container, Grid }                                           from '@material-ui/core'
import { Redirect }                                                  from 'react-router-dom'
import Snackbar                                                      from '../Atoms/Snackbar'

const AddPhoto = () => {

   const [ redirectToPhotoSearch,   setRedirectToPhotoSearch   ] = useState(false)
   const [ snackBarOpen,            setSnackBarOpen            ] = useState(false)

   const handleSnackBar = () => {
      setSnackBarOpen(true)
      setTimeout(() => {
         setRedirectToPhotoSearch(true)
     }, 2500)
   }

   return(
      <Container>
         { redirectToPhotoSearch ? <Redirect to='/PhotoSearch' /> : null }
         <AddPhotoFieldsComponent
            handleSnackBar={handleSnackBar}
            title={'Add Photo Page'}
         />

         {snackBarOpen
            ?
               <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
                  <Grid item sm={6}>
                        <Snackbar msg={'Your photo was added!'} type={'success'} />
                  </Grid>
               </Grid>
            :
               null
         }
      </Container>
   )
}

export default AddPhoto