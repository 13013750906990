/*
 * This is a renderless Component that GETS one announcement based on selected announcement id for:
 * < />
 */
import { useEffect }          from 'react'
import axios                  from 'axios'
import {
   CLIENT_PUBLIC_TOKEN,
   node_server
}                             from '../Helpers'
import PropTypes              from 'prop-types'

const GetOneAnnouncement = ({getAnnouncement, id}) => {

  useEffect( () => {
      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.get(node_server + `announcement/getOneAnnouncement/${id}`)
         .then( res => {
            getAnnouncement(res.data)
            /* Set axios headers back to what they were before. This was causing a cors error when calling the PHP server
            after a GET was made to the Node server. */
            axios.defaults.headers.common = {Accept: "application/json, text/plain, */*"}
         } )
  }, [getAnnouncement, id] )

  return null

}

GetOneAnnouncement.propTypes = {
   getAnnouncement: PropTypes.func,
   id: PropTypes.string
 }

export default GetOneAnnouncement
