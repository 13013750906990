/*
 * This is the Top Level Search Page. If <SearchBoxOne /> sends back a type selection of
 * astronaut, commandant, getallgrads, or generalOfficer <Link /> re-directs to /GetGrads
 * which uses phpFilename from the store to hit the proper php file on the backend
 * 
 * If <SearchBoxOnw /> sends back a type selection of city, state, class, currentJobTitle,
 * currentCompany <SearchBoxTwo /> popuulates with the data sent back from <SearchBoxOne />
 * and when a selection from <SearchBoxTwo /> is made the Submit button shows up. Then <SearchBoxTwo />
 * <Link /> re-directs to /GetGradsByUserId
 * 
 * <Search />
 *      <SearchBoxOne />
 *      <SearchBoxTwo />
 *      <SearchByName />
 *          <SearchByNameDisplay />
 *          <PersonalAndClassStats />
 */

import React, { useCallback, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from "@material-ui/core/Button"
import Grid from '@material-ui/core/Grid'
import { Link } from "react-router-dom"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import SearchBoxOne from '../Components/SearchBoxOne'
import SearchBoxTwo from '../Components/SearchBoxTwo'
import SearchByName from '../Components/SearchByName'

const Search = () => {

    const [ data, setData ] = useState([])
    const [ type, setType ] = useState('')

    /* Get data from <SearchBoxOne /> */
    const getDataFromSearchBoxOne = useCallback((dataFromSearchBoxOne) => {
        setData(dataFromSearchBoxOne.data)
        setType(dataFromSearchBoxOne.type)
    }, [])

    return (
        <Box>
            {/* ======================================================= */}
            <Grid container>
                <Grid item xs={12} style={{ marginTop: "6rem" }} >
                    <Typography variant="h4" >
                        Welcome to the USAF Test Pilot School Alumni Website
                    </Typography>
                </Grid>
            </Grid>
            {/* ======================================================= */}

            {/* ======================================================= */}
            <Grid container >

                <Grid item xs={12} sm={4} style={{ marginTop: "2rem", marginLeft: "2rem" }} >
                    <Typography variant="body1" style={{ textAlign: "left" }} >
                        The initial purpose of this website was to supply TPS with a modern, web-based alumni tracking system
                        that could be easily updated by each alumni member. It also provided alumni members certain search capability
                        in order to locate other registered graduates.<br />
                    </Typography>
                    <Typography variant="body1" style={{ textAlign: "left", marginTop: '1rem' }} >
                        It has now morphed into something of a history project as well. One of the most important things we can do is to keep track of the history of as many TPS graduates as possible. This information is
                        best gathered directly from each grad. Google is a poor substitute, and often there is no information at all. Sometimes information 
                        is available, but frequently only via an obituary. So, each graduate is encouraged to add their own biography or to send a brief one to the 
                        e-mail address below and site staff will add it on their behalf. Information like birth date, birth place, parents, schools
                        attended, commissioning source, early military career, any relevant TPS year info, military career after TPS, life after the military. 
                        Photos you like would be great as well to include official and unofficial class photos.
                    </Typography>
                    <Typography variant="body1" style={{ textAlign: "left", marginTop: '1rem' }} >
                        You can now also enter university information into your profile should you wish. See the
                        Update the Profile option under MENU above.
                    </Typography>
                    <Typography variant="body1" style={{ textAlign: "left", marginTop: '1rem' }} >
                        Send comments / suggestions to: <a href="mailto:admin@usaftpsalumni.com">admin@usaftpsalumni.com</a>
                    </Typography>
{/* Remove the Search functionality from the Search Page for now as it is all from the PHP server and re-working it will take a while, and
    and it no longer works anyway. */}
                    <form>
                        <Grid container>
                            <Grid container spacing={2} direction="row" style={{ margin: "2rem", padding: "0.5rem", border: "1px solid blue", width: "100%", borderRadius: "0.5rem" }}>
                                <Paper style={{ width: "100%", textAlign: "center" }} >
                                    <Typography>
                                        Search By:
                                    </Typography>
                                </Paper>

                                <SearchBoxOne getDataFromSearchBoxOne={getDataFromSearchBoxOne} />

                                <SearchBoxTwo data={data} type={type} />

                                {type === 'astronaut' || type === 'commandant' || type === 'getallgrads' || type === 'generalOfficer' ?
                                    <Grid item md={12} >
                                        <Link to="/GetGrads">
                                            <Button variant="contained" color="primary" >
                                                Submit
                                            </Button>
                                        </Link>
                                    </Grid>
                                    : null}
                            </Grid>
                        </Grid>
                    </form>

                </Grid>

                <Grid item xs={12} sm={7} >
                    <SearchByName />
                </Grid>

            </Grid>
            {/* ======================================================= */}

        </Box>
    )
}

export default Search