/*
 * This Page was cleaned up in GitLab issue #522. Also, on a reported
 * 'good operation' from the server, open a success snackbar, then navigate to the <ShowOneAnnouncement />
 * Page. On 'bad operation' open an error snackbar.
*/

import React, { useState }                   from 'react'
import { announcementIdAction }              from "../Redux/Actions"
import {
   CLIENT_PUBLIC_TOKEN,
   node_server
}                                            from '../Helpers'
import { Redirect }                          from "react-router-dom"
import { store }                             from "../Redux/Store"
import axios                                 from "axios"
import Box                                   from "@material-ui/core/Box"
import Button                                from "@material-ui/core/Button"
// import DatePicker                            from "react-datepicker";                        Comment out to check / remove
// import                                       "react-datepicker/dist/react-datepicker.css";   Comment out to check / remove
import Grid                                  from "@material-ui/core/Grid"
import Snackbar                              from '../Atoms/Snackbar'
import TextareaAutosize                      from "@material-ui/core/TextareaAutosize"
import TextField                             from "@material-ui/core/TextField"
import Typography                            from "@material-ui/core/Typography"

const NewAnnouncement = () => {

   const [ announcementIndex,                setAnnouncementIndex                ]  =  useState(-1)
   const [ openSnackbar,                     setOpenSnackbar                     ]  =  useState(false)
   const [ paragraph,                        setParagraph                        ]  =  useState([])
   const [ snackbarMsg,                      setSnackbarMsg                      ]  =  useState('')
   const [ snackbarType,                     setSnackbarType                     ]  =  useState('')

   /* Comment out for now as this is not currently used. */
   // const [ expirationDate,                   setExpirationDate                   ]  =  useState('')
   const [ navigateToOneAnnouncementPage,    setNavigateToOneAnnouncementPage    ]  =  useState(false)
   const [ paragraphText,                    setParagraphText                    ]  =  useState('')

   const [ title,                            setTitle                            ]  =  useState('')
   const [ synopsis,                         setSynopsis                         ]  =  useState('')

   const handleAddParagraph = () => {
      const tempParagraph = paragraph
      tempParagraph.push(paragraphText)
      setParagraph(tempParagraph)
      setParagraphText('')
   }

   const handleAnnouncementIndexChange = (e) => {
      setAnnouncementIndex(e.target.value)
   }

   /* Comment out for now as this is not currently used. */
   // const handleDateChange = date => {
   //    setExpirationDate(date)
   //    console.log('expiration date is', date)
   // }

   const handleParagraphTextChange = (e) => {
      setParagraphText(e.target.value)
   }

   const handleTitleChange = (e) => {
      setTitle(e.target.value)
   }

   const handleSynopsisChange = (e) => {
      setSynopsis(e.target.value)
   }

   const handleSubmit = () => {

      axios.defaults.headers.common = {Accept: "application/json, text/plain, */*", 'Authorization': `Bearer ${CLIENT_PUBLIC_TOKEN}`}
      axios.post(node_server + 'announcement', {
         announcementIndex,
         paragraph,
         synopsis,
         title
      })
         .then( res => res.data )
         .then( data => {
               if(data.msg === 'good_operation'){
                  setSnackbarMsg('Good Operation')
                  setSnackbarType('success')
                  setOpenSnackbar(true)
                  store.dispatch(announcementIdAction(data.announcementToDB._id))
               }
               else {
                  setSnackbarMsg('Bad Operation')
                  setSnackbarType('error')
                  setOpenSnackbar(true)
               }
               setTimeout(() => {
                  setSnackbarMsg('')
                  setSnackbarType('')
                  setOpenSnackbar(false)
                  setNavigateToOneAnnouncementPage(true)
               }, 2500);
               return 1
         })

   }

   const paragrapshToDisplay = paragraph.map( p => {
      return (
         <Grid item xs={12} >
            <Typography style={{ textAlign: 'left', marginLeft: '5rem', marginBottom: '1rem' }}>{ p }</Typography>
         </Grid>
      )
   })

   return (
      <Box style={{ marginTop: "6rem" }}>
            <Typography variant="h3">Add a New Announcement</Typography>

            {navigateToOneAnnouncementPage ? <Redirect to="/ShowOneAnnouncement" /> : null}

            {openSnackbar
               ?
                  <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
                     <Grid item xs={5} style={{marginTop: '8px'}} >
                        <Snackbar msg={snackbarMsg} type={snackbarType} />
                     </Grid>
                  </Grid>
               :
                  null
            }

            <form >
               <Grid container direction="row" justifyContent="center" style={{ width: "90%" }} >
                  <Grid item xs={10} style={{ width: '20%' }}>
                        <TextField fullWidth label="Announcement Index" name="announcement-index" onChange={handleAnnouncementIndexChange} />
                  </Grid>
                  <Grid item xs={10}>
                        <TextField fullWidth label="Title" name="title" onChange={handleTitleChange} />
                  </Grid>
                  <Grid item xs={10}>
                        <Typography style={{ marginTop: "1.5rem", textAlign: "left" }}>
                           Synopsis ( Please keep the synopsis to 3 - 5 sentences if possible )
                        </Typography>
                        <TextareaAutosize data-testid='announcement-synopsis-textarea' label="Synopsis" name="announcementSynopsis" minRows={3} style={{ width: "100%", fontSize: "1rem" }} onChange={handleSynopsisChange} />
                  </Grid>
                  {paragrapshToDisplay}
                  <Grid item xs={10}>
                        <Typography style={{ marginTop: "1.5rem", textAlign: "left" }}>
                           Paragraph(s)
                        </Typography>
                        <TextareaAutosize data-testid='announcement-paragraph-textarea' label="Announcement Paragraph" name="paragraph" minRows={5} style={{ width: "100%", fontSize: "1rem" }} onChange={handleParagraphTextChange} value={paragraphText} />
                        <Grid item xs={3} style={{ marginTop: "1.5rem" }}>
                           <Button data-testid='add-paragraph-button' variant="contained" color="primary" style={{ justifySelf: "left" }} onClick={handleAddParagraph}>Add Paragraph</Button>
                        </Grid>
                  </Grid>
                  <Grid item xs={3} style={{ marginTop: "0.5rem" }}>
                        {/* <MySelect name="priority" label="Priority" options={priorities} onChange={handleChange} /> */}
                  </Grid>

                        {/* Comment out for now as this is not currently used. */}
                        {/* <Grid item xs={3} style={{ marginTop: "1.5rem" }}>
                           <Typography>Expiration</Typography>
                           <DatePicker showTimeSelect  onChange={handleDateChange}
                              timeFormat="HH:mm" timeIntervals={15} timeCaption="time" dateFormat="MMMM d, yyyy h:mm aa"
                           />
                        </Grid> */}

                  <Grid item xs={3} style={{ marginTop: "1.5rem" }}>
                        <Button data-testid='new-announcement-submit-button' variant="contained" color="primary" style={{ justifySelf: "left" }} onClick={handleSubmit}>Submit</Button>
                  </Grid>
               </Grid>
            </form>
      </Box>
   )
}

export default NewAnnouncement